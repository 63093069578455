import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['hiddenInformation']
  static values = {
    moreTranslation: String,
    lessTranslation: String,
    plusSvg: String,
    minusSvg: String
  }

  // The toggle method is called when the toggle event (clicking on the corresponding div) is triggered.
  // It changes the state of the hiddenInformationTarget element and updates the button text and button SVG accordingly.
  toggle(event) {
    this.hiddenInformationTarget.classList.toggle('hidden')
    this.hiddenInformationTarget.toggleAttribute('open')

    const toggleButton = event.currentTarget;
    const toggleTarget = this.hiddenInformationTarget;
    const attributeOpen = toggleTarget.hasAttribute('open')

    toggleButton.querySelector('img').src = attributeOpen ? this.minusSvgValue : this.plusSvgValue
    toggleButton.querySelector('span').innerHTML = attributeOpen ? this.lessTranslationValue : this.moreTranslationValue
  }
}
