import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="messenger-message-read"
export default class extends Controller {
  static targets = [ "message" ]
  connect() {
    if (this.hasMessageTarget && this.messageTarget.dataset.messageUnread === "1") {
      console.log('message is unread starting timer for messageId: ' + this.messageTarget.dataset.messageId)
      this.startTimerForReadMark(this.messageTarget)
    } else {
      console.log('message (messageId: ' + this.messageTarget.dataset.messageId + ' is already read')
    }
  }

  metaContent(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element && element.content
  }

  messageTargetDisconnected() {
    clearTimeout(this.messageTimeout)
  }

  startTimerForReadMark(message) {
    this.messageTimeout = setTimeout(this.markMessageAsRead.bind(null, message), 5000)
  }

  markMessageAsRead(message) {
    // Create a new XMLHttpRequest object
    const xhr = new XMLHttpRequest();

    // Define the callback function to be called when the AJAX call is complete
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        // The AJAX call is complete, so check the return value
        if (xhr.status === 200) {
          message.dataset.messageUnread = false
        } else {
          console.log('could not mark message as read - id: ' + message.dataset.messageId)
        }
      }
    }

    // Send the AJAX POST request to the specified URL with the user_id parameter
    xhr.open("POST", document.head.querySelector(`meta[name="hostUrl"]`).content + "api/v1/conversations/messages/read", true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send("message_id=" + encodeURIComponent(message.dataset.messageId) + "&user_id=" + encodeURIComponent(message.dataset.readingUserId));
  }
}
