import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-message"
export default class extends Controller {
  // targets have to be defined as data attribute on the elements data-[controller-name]-target="myTarget" (data attribute targets in camelCase!)
  // and here at targets too - afterwards they can be accessed by this.myTargets (plural), this.myTarget (first of list)
  // see: https://stimulus.hotwired.dev/reference/targets
  static targets = ['flashMessage']

  move(toast) {
    let progressBar = toast.querySelector('#' + toast.id + '-progress-bar')
    let delay = parseInt(progressBar.dataset.timeDelay)
    let startFrom = parseInt(progressBar.dataset.timeLeft)

    var end = Date.now() + startFrom;
    var frame = () => {
      let timeLeft = Math.max(0, end - Date.now());
      progressBar.setAttribute('data-time-left', timeLeft.toString());
      progressBar.style.width = (100*timeLeft)/delay + '%';
      progressBar.style.left = (100 - (100*timeLeft)/delay) + '%';

      // console.log('isPaused: ' + toast.dataset.isPaused === 'true')
      if (timeLeft === 0) {
        toast.remove();
        return;
      } else if (toast.dataset.isPaused === 'true') {
        return
      } else {
        requestAnimationFrame(frame);
      }
    };
    requestAnimationFrame(frame);
  }

  connect() {
    console.dir(this.flashMessageTargets)
    this.flashMessageTargets.forEach((toast) => {
      toast.addEventListener('mouseenter', () => {
        toast.setAttribute('data-is-paused','true');
      })

      toast.addEventListener('mouseleave', () => {
        toast.setAttribute('data-is-paused','false');

        this.move(toast)
      })

      toast.querySelector('#' + toast.id + '-btn').addEventListener('click', () => {
        toast.remove()
      })

      this.move(toast)
    })
  }
}
