import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="interview"
export default class extends Controller {
  // targets have to be defined as data attribute on the elements data-[controller-name]-target="myTarget" (data attribute targets in camelCase!)
  // and here at targets too - afterwards they can be accessed by this.myTargets (plural), this.myTarget (first of list)
  // see: https://stimulus.hotwired.dev/reference/targets
  static targets = ['communicationTypeSelect', 'communicationAccessLabel', 'communicationAccessTextArea',
    'msTeamsHint', 'communicationAccessWrapper', 'externalAppointmentHint', 'appointmentLabel', 'appointmentInput']
  connect() {
    console.dir(this.appointmentInputTargets)
    // we require information for all interview options but mx_teams since we process teams interviews different
    this.communicationTypeSelectTarget.addEventListener('change', (event) => {
      if (this.communicationTypeSelectTarget.value === 'ms_teams') {
        this.communicationAccessWrapperTarget.classList.add('hidden')
        this.msTeamsHintTarget.classList.remove('hidden')
        this.hideCommunicationAccess()
        this.externalAppointmentHintTarget.classList.add('hidden')
      } else if (this.communicationTypeSelectTarget.value === 'external_appointment') {
        this.hideCommunicationAccess()
        this.msTeamsHintTarget.classList.add('hidden')
        this.communicationAccessWrapperTarget.classList.remove('hidden')
        this.externalAppointmentHintTarget.classList.remove('hidden')
      } else {
        this.showCommunicationAccess()
        this.msTeamsHintTarget.classList.add('hidden')
      }
    })

    // this is to hide / show ms teams hint when editing an existing interview
    if (this.communicationTypeSelectTarget.value === 'ms_teams') {
      this.hideCommunicationAccess()
      this.msTeamsHintTarget.classList.remove('hidden')
      this.communicationAccessWrapperTarget.classList.add('hidden')
      this.externalAppointmentHintTarget.classList.add('hidden')
    } else if (this.communicationTypeSelectTarget.value === 'external_appointment') {
      this.hideCommunicationAccess()
      this.msTeamsHintTarget.classList.add('hidden')
      this.communicationAccessWrapperTarget.classList.remove('hidden')
      this.externalAppointmentHintTarget.classList.remove('hidden')
    } else {
      this.showCommunicationAccess()
    }
  }

  checkCommunicationTypeSelection() {

  }

  hideCommunicationAccess() {
    this.communicationAccessTextAreaTarget.removeAttribute('required')
    this.communicationAccessLabelTarget.classList.remove('required')
    this.communicationAccessWrapperTarget.classList.add('hidden')
  }

  showCommunicationAccess() {
    this.communicationAccessTextAreaTarget.setAttribute('required', true)
    this.communicationAccessLabelTarget.classList.add('required')
    this.communicationAccessWrapperTarget.classList.remove('hidden')
    this.externalAppointmentHintTarget.classList.add('hidden')
    this.externalAppointmentHintTarget.classList.add('hidden')
  }

  insertPhoneInterviewData() {
    // once telephone is selected insert phone and mobile as communication_access
    // vielleicht einen Button unter dem TextArea mit "Meine Nummern übernehmen"? wenn ich einen api endpunkt aufmache, oeffne ich die Tuer fuer illegalen Zugriff auf persoenliche Daten
  }

  insertPersonalInterviewData() {
    // once personal_meeting is selected insert employer institution address as communication_access
    // vielleicht einen Button unter dem TextArea mit "Firmenadresse übernehmen"? wenn ich einen api endpunkt aufmache, oeffne ich die Tuer fuer illegalen Zugriff auf persoenliche Daten
  }
}
