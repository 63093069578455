import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['hiddenMenu', 'accountMenuButton']

  connect() {}
  toggle() {
    setTimeout(() => {
      this.hiddenMenuTarget.classList.toggle('open')
    }, 150);
  }

  accountMenuButtonTargetConnected() {
    console.log('button present')
    // Workaround for Safari as it does not focus buttons on click
    this.accountMenuButtonTarget.addEventListener('click', () => {
      this.accountMenuButtonTarget.focus()
    })
  }
}
