import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="js"
export default class extends Controller {
  static values = { loaded: { type: Boolean, default: false } }

  connect () {
    this.loadedValue = true
  }
}
