import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="help-center"
export default class extends Controller {
  // targets have to be defined as data attribute on the elements data-[controller-name]-target="myTarget" (data attribute targets in camelCase!)
  // and here at targets too - afterwards they can be accessed by this.myTargets (plural), this.myTarget (first of list)
  // see: https://stimulus.hotwired.dev/reference/targets
  static targets = ['helpTopicPreview', 'helpTopicContentWrapper', 'canonical']
  connect() {
    this.helpTopicPreviewTarget.classList.add('bg-gray-100')

    this.helpTopicPreviewTargets.forEach((preview) => {
      preview.addEventListener('click', (p) =>{
        // all buttons but the clicked one
        this.helpTopicPreviewTargets.filter(item => item !== preview).forEach((inactivePreview) => {
          inactivePreview.classList.remove('bg-gray-100')
        })
        preview.classList.add('bg-gray-100')
      })
    })

    if(this.hasCanonicalTarget) {
      console.log('canonical present')
      document.querySelector("a[href*=" + this.canonicalTarget.dataset.canonical + "]").click()
    } else {
      console.log('canonical missing')
    }
  }
}
