// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();

require("@hotwired/turbo-rails");
import "./../controllers"

// nested forms
require('./nested-fields/addFields')
require('./nested-fields/removeFields')

require('flatpickr')
const German = require("flatpickr/dist/l10n/de.js").default.de;
//https://flatpickr.js.org

require('bootstrap')
import "../stylesheets/public";
import "../stylesheets/cookie_alert";

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// const images = require.context('../media/images', true)
// const imagePath = (name) => images(name, true)
document.addEventListener("turbolinks:load", function () {
  $(".datetimepicker").flatpickr({
    "locale": "de",
    enableTime: true,
    dateFormat: "d.m.Y H:i",
    time_24hr: true,
    weekNumbers: true,
    minuteIncrement: 15,
    minDate: "today",
    defaultDate: new Date().setHours(10, 0, 0),
    inline: true,
    minTime: "07:00",
    maxTime: "20:00",
  });
})
