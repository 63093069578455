import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multi-select-event"
export default class extends Controller {
  static targets = [ 'selectInput' ]
  connect() {
    this.selectInputTargets.forEach((selectInput) => {
      console.dir(selectInput)
      selectInput.addEventListener('change', () => {
        console.log('change event triggered')
      })
    })

    // this.selectInputTargets.forEach((selectInput) => {
    //   selectInput.bind('DOMSubtreeModified', () => {
    //     let changeEvent = new Event('change')
    //     selectInput.dispatchEvent(changeEvent)
    //   })
    // })


    var whatToObserve = {childList: true, attributes: true, subtree: true, attributeOldValue: true, attributeFilter: ['class', 'style']};
    var mutationObserver = new MutationObserver(function(mutationRecords) {
      mutationRecords.forEach((index, mutationRecord) => {
        if (mutationRecord.type === 'childList') {
          if (mutationRecord.addedNodes.length > 0) {
            //DOM node added, do something
          }
          else if (mutationRecord.removedNodes.length > 0) {
            //DOM node removed, do something
            console.log('added')
          }
        }
        else if (mutationRecord.type === 'attributes') {
          if (mutationRecord.attributeName === 'class') {
            //class changed, do something
            console.log('removed')
          }
        }
      })
    });
    mutationObserver.observe(document.body, whatToObserve);
  }
}
